<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="headline">
                <h1 class="headline__title">{{ $translate('modify-a-dispatch') }}</h1>
                <p class="headline__text">{{ $translate('here-you-can-modify-a-dispatch') }}</p>
            </div>
            <div class="tabs-wrapper">  
                <ul class="tabs">
                    <li class="tabs__item">
                        <a data-tab="info" class="nav-tab" @click="switchTab('info')" :class="isActiveTab('info')"> {{ $translate('basic-info') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="companies" class="nav-tab" @click="switchTab('companies')" :class="isActiveTab('companies')"> {{ $translate('select-companies') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="products" class="nav-tab" @click="switchTab('products')" :class="isActiveTab('products')"> {{ $translate('products') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="documents" class="nav-tab" @click="switchTab('conditions')" :class="isActiveTab('conditions')"> {{ $translate('contract-conditions') }} </a>
                    </li>
                </ul>
            </div>
            <div class="content__body">
                <div class="tab-content">
                    <div id="info" class="tab-content__item" :class="isActiveTab('info')">
                        <div class="wizard-item">
                            <div class="items-group cards">
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title" >{{ $translate('dispatch-id') }}</label>
                                        <input type="text" class="input" placeholder="123456789" v-model="dispatchID"/>
                                    </div>
                                </div>
                            </div>
                            <hr class="line-hr my-25" />
                        </div>
                        <div class="headline-wrapper flex items-center">
                            <div class="headline headline--sm flex-auto">
                                <h2 class="headline__title">{{ $translate('dispatch-documents') }}</h2>
                                <p class="headline__text">{{ $translate('list-of-documents-attached-to-despatch') }}</p>
                            </div>
                        </div>
                        <ul class="editable-list">
                            <li class="editable-list__item" v-for="d in documents" :key="d.name">
                                <div class="data-row">
                                    <ul class="data-row__list">
                                        <li class="data-row__item">
                                            <span class="data-row__icon">
                                                <inline-svg :src="require(`@/assets/img/File-Pdf.svg`)" />
                                            </span>
                                            {{d.name}}
                                        </li>
                                    </ul>
                                    <div class="data-row__controls">
                                        <a :href="getDocumentLink(d)" download="" target="_blank" class="btn btn-transp">{{ $translate('view-document') }}</a>

                                        <button class="btn btn-icon red-color" @click="deleteDocument(d.name)">
                                            <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <label type="button" class="btn btn-w-shadow btn-standart-2 label-btn">
                            <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                            <span>{{ $translate('add-document') }}</span>
                            <input style="display:none" type="file"  accept=".docx,.txt" multiple @change="addDocuments"/>
                        </label>
                    </div>
                    <div id="companies" class="tab-content__item" :class="isActiveTab('companies')">
                        <div class="addition">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">{{ $translate('select-companies') }}</h2>
                                    <p class="headline__text">{{ $translate('add-various-companies-to-your-dispatch') }}</p>
                                </div>
                            </div>
                            <ul class="editable-list" >
                                <li class="editable-list__item" v-if="backup.owner">
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">{{backup.owner.company.company_name}}</li>
                                            <li class="data-row__item">
                                                <span class="table-user flex items-center"><img :src="profile_photo" alt="" /> {{companyData.user.fullname}}</span>

                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="editable-list__item" v-for="c in dispatchCompanies" :key="c.id">
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">{{c.company_name}}</li>
                                            <li class="data-row__item">
                                                <span class="table-user flex items-center"><img :src="c.profile_photo_src" alt="" />{{c.user.fullname||'-'}}</span>
                                            </li>
                                        </ul>
                                        <div class="data-row__controls">
                                            <button class="btn btn-icon red-color" @click="deleteFieldItem('companies',c.id)">
                                                <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                            <WhitePlusButton @click="addCompaniesOpen">{{ $translate('add-companies') }}</WhitePlusButton>

                        </div>
                    </div>
                    <div id="products" class="tab-content__item" :class="isActiveTab('products')">
                        <div class="addition">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">{{ $translate('dispatch-products') }}</h2>
                                    <p class="headline__text">{{ $translate('list-of-batches-and-products-attached-to-dispatch') }}</p>
                                </div>
                                <div class="buttons-group add-buttons">
                                    <button type="button" class="btn btn-w-shadow btn-standart-2">
                                        <inline-svg :src="require(`@/assets/img/ico-qr-code.svg`)" />
                                        <span>{{ $translate('qr-code') }}</span>
                                    </button>
                                    <button type="button" class="btn btn-w-shadow btn-standart-2" data-target="qr-code-settings">
                                        <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                                        <span>{{ $translate('qr-code-settings') }}</span>
                                    </button>
                                    <button type="button" class="btn btn-w-shadow btn-standart-2">
                                        <!-- <a href="csvExample.csv" download="">
                                        </a> -->
                                        <inline-svg :src="require(`@/assets/img/document-file-table.svg`)" />
                                        <span>{{ $translate('csv-example') }}</span>
                                    </button>
                                    <button type="button" class="btn btn-w-shadow btn-standart-2" @click="openUploadCsv">
                                        <inline-svg :src="require(`@/assets/img/upload-arrow.svg`)" />
                                        <span>{{ $translate('upload-csv') }}</span>
                                    </button>
                                    <WhitePlusButton @click="addBatchOpen">{{ $translate('add-batch') }}</WhitePlusButton>
                                </div>
                            </div>
                            <ul class="editable-list">
                                <li class="editable-list__item" v-for="b in batches" :key="b.id">
                                    <div class="data-row --inner">
                                        <div class="data-row__spoiler spoiler flex-auto --style-2">
                                            <div class="spoiler__button flex items-center" @click="openOptions">
                                                <inline-svg :src="require(`@/assets/img/Arrow-down.svg`)" />
                                                <div class="spoiler__title flex-auto"><span>{{ $translate('batch') }}:</span>{{b.name}}</div>
                                                <div class="buttons-group add-buttons">
                                                    <button type="button" class="btn btn-transp" data-target="qr-code" @click.self.prevent="()=>{}">
                                                        <inline-svg :src="require(`@/assets/img/ico-qr-code.svg`)" />
                                                        <span>{{ $translate('qr-code') }}</span>
                                                    </button>
                                                    <button type="button" class="btn btn-transp" data-target="qr-code-settings">
                                                        <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                                                        <span>{{ $translate('qr-code-settings') }}</span>
                                                    </button>
                                                    <button type="button" class="btn btn-transp" @click="addProductsOpen(b.id)">
                                                        <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                                                        <span>{{ $translate('add-products') }}</span>
                                                    </button>
                                                    <button type="button" class="btn btn-transp red-color" @click="deleteBatch(b.id)">
                                                        <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                                        <span>{{ $translate('delete-batch') }}</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="spoiler__body flex flex-col">
                                                <div class="table-wrapper" v-if="fieldKeys(b.id).length>0">
                                                    <table class="table style-2">
                                                        <thead>
                                                            <tr>
                                                                <th style="width: 60%">
                                                                    <label class="module__check">
                                                                        <input type="checkbox" name="privacy_policy" :checked="isCheckedAll[b.id]" @change="toggleAllCheckedInputs(b.id)"/>
                                                                        <span class="check"></span>
                                                                        <span class="text">{{ $translate('product-name') }}</span>
                                                                    </label>
                                                                </th>
                                                                <th>{{ $translate('invoice-number') }}</th>
                                                                <th>{{ $translate('po-number') }}</th>
                                                                <th colspan="2">{{ $translate('quantity&qnit') }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="p in fieldValues(b.id, products)" :key="p.id">
                                                                <td>
                                                                    <label class="module__check">
                                                                        <input type="checkbox" name="privacy_policy" :checked="checkedInputs[b.id]?checkedInputs[b.id][p.id]:false" @change="setCheckedInputs($event,b.id,p.id)"/>
                                                                        <span class="check"></span>
                                                                        <a :href="`/products/${p.id}`" target="_blank" class="text">{{p.title}}</a>
                                                                    </label>
                                                                </td>
                                                                <td>{{p.invoice_number||'-'}}</td>
                                                                <td>{{p.purchase_order||'-'}}</td>
                                                                <td>{{(p.quantity+p.units)||'-'}}</td>
                                                               
                                                                <td>
                                                                    <button class="btn btn-icon red-color" @click="deleteFieldItem(b.id,p.id)">
                                                                        <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="spoiler__body-actions flex" v-else>
                                                    {{ $translate('no-added-products') }}
                                                </div>

                                                <div class="spoiler__body-actions flex">
                                                    <div class="buttons-group add-buttons flex-auto">
                                                        <button type="button" class="btn btn-transp" data-target="qr-code">
                                                            <inline-svg :src="require(`@/assets/img/ico-qr-code.svg`)" />
                                                            <span>{{ $translate('qr-code') }}</span>
                                                        </button>
                                                        <button type="button" class="btn btn-transp">
                                                            <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                                                            <span>{{ $translate('qr-code-settings') }}</span>
                                                        </button>
                                                        <button type="button" class="btn btn-transp" @click="addProductsOpen(b.id)">
                                                            <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                                                            <span>{{ $translate('add-products') }}</span>
                                                        </button>
                                                    </div>
                                                    <button type="button" class="btn btn-transp red-color" @click="deleteBatch(b.id)">
                                                        <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                                        <span>{{ $translate('delete-batch') }}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div class="buttons-group add-buttons">
                                <WhitePlusButton @click="addBatchOpen">Add Batch</WhitePlusButton>
                                <button type="button" class="btn btn-w-shadow btn-standart-2 ml-auto">
                                    <inline-svg :src="require(`@/assets/img/ico-qr-code.svg`)" />
                                    <span>{{ $translate('qr-code') }}</span>
                                </button>
                                <button type="button" class="btn btn-w-shadow btn-standart-2" data-target="qr-code-settings">
                                    <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                                    <span>{{ $translate('qr-code-settings') }}</span>
                                </button>
                                <button type="button" class="btn btn-w-shadow btn-standart-2">
                                    <inline-svg :src="require(`@/assets/img/document-file-table.svg`)" />
                                    <span>{{ $translate('csv-example') }}</span>
                                </button>
                                <button type="button" class="btn btn-w-shadow btn-standart-2" @click="openUploadCsv">
                                    <inline-svg :src="require(`@/assets/img/upload-arrow.svg`)" />
                                    <span>{{ $translate('upload-csv') }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="conditions" class="tab-content__item" :class="isActiveTab('conditions')">
                        <div class="addition">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">{{ $translate('add-conditions') }}</h2>
                                    <p class="headline__text">{{ $translate('add-conditions-to-your-contract-dispatch') }}</p>
                                </div>
                                <div class="buttons-group add-buttons">
                                   
                                    <WhitePlusButton @click="openAddSmartCondition">
                                        {{ $translate('add-smart-condition') }}
                                    </WhitePlusButton>
         
                                    <WhitePlusButton @click="openAddBusinessCondition">
                                        {{ $translate('add-business-condition') }}
                                    </WhitePlusButton>

                                </div>
                            </div>
                            <ul class="editable-list">
                                <li class="editable-list__item" v-for="c in smartConditions" :key="c.id">
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">
                                                <span class="data-row__icon">
                                                    <inline-svg :src="require(`@/assets/img/ico-signal.svg`)" />
                                                </span>
                                                {{ $translate('smart-conditions') }}
                                            </li>
                                            <li class="data-row__item">
                                                <span class="data-row__span">{{ $translate('batch') }}: </span>
                                                {{findField(batches,c.select_batch_id,"name")}}

                                            </li>
                                            <li class="data-row__item">
                                                <span class="data-row__span">{{ $translate('device') }}: </span>
                                                {{findField(devices,c.select_device,"name")}}
                                            </li>
                                        </ul>
                                        <div class="data-row__controls">
                                            <button type="button" class="btn btn-icon" @click="editSmartCondition(c.id)">
                                                <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                                            </button>
                                            <button type="button" class="btn btn-icon red-color" @click="deleteSmartCondition(c.id)">
                                                <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                                <li class="editable-list__item" v-for="c in businessConditions" :key="c.id">
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">
                                                <span class="data-row__icon">
                                                    <inline-svg :src="require(`@/assets/img/suitcase-portfolio.svg`)" />
                                                </span>
                                                {{ $translate('business-condition') }}
                                            </li>
                                            <li class="data-row__item">
                                                <span class="data-row__span">{{ $translate('batch') }}: </span>
                                                {{findField(batches,c.select_batch_id,"name")}}
                                            </li>
                                        </ul>
                                        <div class="data-row__controls">
                                            <button type="button" class="btn btn-icon" @click="editBusinessCondition(c.id)">
                                                <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                                            </button>
                                            <button type="button" class="btn btn-icon red-color" @click="deleteBusinessCondition(c.id)">
                                                <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </main>
       
        <FooterTabsControl
            previous=1
            @cancelAction="restoreFromBackup"
            confirmName="Save"
            @confirm="confirmAction"
            @swipeTab="swipeTab"
            :pageTabs="pageTabs"
            :activeTab="activeTab"
            :processing="processing"

        />
       


        <QrCodeSettings v-if="qrCodeSettings" @close="closeQrCodeSettings"></QrCodeSettings>
        <QrCode v-if="qrCode" @close="closeQrCode"></QrCode>
        <CsvExample v-if="csvExample" @close="closeCsvExample" @confirm="confirmCsvExample"></CsvExample>
        <UploadCsv v-if="uploadCsv" @close="closeUploadCsv" @submit="parseUploadCSV"></UploadCsv>

        <AddSmartCondition 
            v-if="addSmartCondition.show" 
            :condition="addSmartCondition.condition"
            @submit="submitAddSmartCondition" 
            @close="closeAddSmartCondition"
            :batches="batches"
            :devices="devices">
        </AddSmartCondition>
        <AddBusinessCondition 
            v-if="addBusinessCondition.show" 
            :condition="addBusinessCondition.condition"
            @submit="submitAddBusinessCondition" 
            @close="closeAddBusinessCondition"
            :batches="batches">
        </AddBusinessCondition>
        
        
        <AddBatch
            v-if="addBatchPopupInfo.show"
            @close="addBatchClose"
            @submit="addBatchSubmit"
        ></AddBatch>
        <AddProducts
            v-if="addProductsPopupInfo.show"
            :products="addProductsPopupInfo.products"
            @close="addProductsClose"
            @submit="addProductsSubmit"
            :processing="aProcessing"
        />
        <AddItems
            v-if="addCompaniesPopupInfo.show"
            :items="addCompaniesPopupInfo.companies"
            @close="addCompaniesClose"
            @submit="addCompaniesSubmit"
            :processing="aProcessing"
        >
            <template v-slot:firstColumn="slotData">
                <span class="text">
                    {{slotData.r.company_name}}
                </span>
            </template>
            <template v-slot:columns="slotData">
                <li class=data-row__item>
                    {{slotData.r.id}}
                </li>
            </template>
        </AddItems>

    </div>
</template>

<script>
import {
	updateDispatch,
    createCompanyDispatch,
    deleteCompanyDispatch,
    createDispatch
} from '@/graphql/mutations'
import { API,Storage } from "aws-amplify";
import { getDispatch,listCompanies } from "@/graphql/queries";
import Base from "@/components/base";
import Popups from "@/components/popups";
import Buttons from "@/components/buttons"
import { mixTabs,mixArrayFields,mixProcessing,mixDeleteItem } from '@/mixins';
import {parseCVSconfig} from "@/config/dispatch"
export default {
    name: "DispachModify",
    props:["id"],
    components: {
        ...Base,
        ...Popups,
        ...Buttons,
    },
    mixins: [mixTabs,mixArrayFields,mixProcessing,mixDeleteItem],
    watch:{
        async "arrayFields.companies"(){
            console.log("new");
            this.dispatchCompanies=await this.getDispatchCompanies()
        }
    },

    data() {
        return {
            dispatchID:null,
            documents:[],
            qrCodeSettings: 1,
            qrCode: null,
            csvExample: false,
            uploadCsv: false,
            
            pageTabs:['info', 'companies', 'products', 'conditions'],



            addBatchPopupInfo:{
                show:false
            },
            batches:[],
            arrayFields:{
                companies:[]
            },
            addProductsPopupInfo:{
                show:false,
                batchID:null,
                products:null,
            },
            isCheckedAll:{

            },
            checkedInputs:{

            },
            addSmartCondition:{
                show:false,
                condition:null
            },
            addBusinessCondition: {
                show:false,
                condition:null
            },
            smartConditions:[],
            businessConditions:[],
            addCompaniesPopupInfo:{
                show:false,
                item:null
            },
            documents:[],
            backup:[],
            companiesSource:[],
            dispatchCompanies:[],

        };
    },
    computed: {
        isNew(){
            console.log(this.$route.query);
            return !!this.$route.query.fromreject
            // return false
        },
        products() {
            return this.$store.state.products || [];
        },
        devices() {
            return this.$store.state.devices || [];
        },
        userData() {
			return this.$store.state.userData || {}
		},
        companyData() {
			return this.$store.state.companyData || {}
		},
        profile_photo() {
			return this.$store.state.profile_photo
		},
      
	},
    async created(){
        const companies = await API.graphql({
            query: listCompanies,
            variables: {
                
            }
        }).then(res => res.data.listCompanies.items)
        this.companiesSource=companies
        await this.getDispatch()
    },
    methods: {
        async confirmAction(){
            if(this.isNew){
                await this.createDispatch()
            }else{
                await this.modifyDispatch()
            }
        },
        async getDispatchCompanies(){
            let fieldValues=this.fieldValues('companies',this.companiesSource)
            if(!this.companiesSource||!fieldValues){
                return
            }
            let requests=fieldValues.map(async company=>{
                return {
                    ...company,
                    profile_photo_src:company.user.profile_photo?await Storage.get(company.user.profile_photo):require("@/assets/img/user.png")
                }
            })
            return await Promise.all(requests).then(res=>res)
        },
        async getDispatch(){
            let dispatchSource= await API.graphql({
                query: getDispatch,
                variables: {
                    id: this.id,
                },
            }).then((res) => res.data.getDispatch);
            this.backup=JSON.parse(JSON.stringify(dispatchSource))
            await this.setDispatch(dispatchSource)
        },
        async setDispatch(dispatch){
            console.log('dispatch to modify',dispatch);
            this.dispatchID=dispatch.dispatchID
            this.documents=[]
            
            this.batches=[]
            this.arrayFields={}
            this.smartConditions=[]
            this.businessConditions=[]
            
            let documentsRequets=dispatch.documents.map(async d=>{
                return {
                    name:d.title,
                    isLoaded:true,
                    url: await Storage.get(d.key), 
                    ...d
                }
            })
            await Promise.all(documentsRequets).then(res=>this.documents=res)
            
            this.setFieldItems("companies",
                this.isNew ?
                [this.companyData.id] :
                this.transformFieldToId(dispatch.companies.items,"companyID").filter(c=>c!==dispatch.owner.company.id)
            )
            dispatch.batches.forEach(b=>{
                let newBatch={...b}
                delete newBatch.products
                this.addBatchSubmit(newBatch)
                this.addFieldItems(b.id,b.products)
            })
            // this.addFieldItems("companies",this.transformFieldToId(dispatch.companies))
            dispatch.contract_conditions.forEach((c,index)=>{
                if(c.condition_type=="smart"){
                    this.smartConditions.push({
                        condition_type:"smart",
                        id: Date.now()+""+index,
                        select_batch_id:c.select_batch_id,
                        select_device:c.select_device,
                        parameter_obeserved:c.parameter_obeserved,
                        operator:c.operator,
                        value:c.value,
                        from_date:c.from_date,
                        from_time:c.from_time,
                        to_date:c.to_date,
                        to_time:c.to_time
                    })
                }
                if(c.condition_type=="business"){
                    this.businessConditions.push({
                        condition_type:"business",
                        id: Date.now()+""+index,
                        select_batch_id: c.select_batch_id,
                        type: c.type,
                        event_to_be_monitored: c.event_to_be_monitored,
                        value: c.value,
                        from_date: c.from_date,
                        from_time: c.from_time,
                        to_date: c.to_date,
                        to_time: c.to_time,
                        date:c.date,
                        time:c.time,
                        description_of_certifications_required:c.description_of_certifications_required,
                        description_of_the_business_rule:c.description_of_the_business_rule
                    })
                }
            })
        },
        async restoreFromBackup() {
            await this.setDispatch(this.backup);
            this.$router.push({ path: '/dispatch' });
        },
        addCompaniesOpen(){
            this.addCompaniesPopupInfo.show=true
            this.addCompaniesPopupInfo.companies=this.fieldValuesFree("companies",this.companiesSource.filter(c=>{
                return c.id!==this.userData.userCompanyId
            }),)
        },
        addCompaniesClose(){
            this.addCompaniesPopupInfo.show=false
            this.addCompaniesPopupInfo.companies=null
        },
        addCompaniesSubmit(companies){
            this.addFieldItems("companies",companies)
            this.addCompaniesClose()
        },
        addDocuments(ev){
            const files = ev.target.files;
			if(!files.length) return
            // this.documents=[...this.documents,...files]
            this.documents=[...files,...this.documents]
        },
        deleteDocument(name){
            this.documents.splice(this.documents.findIndex(d=>d.name==name),1)
        },
        async modifyDispatch(){
            this.processing=true
            let payloadDocuments=[]
            for(let d of this.documents){
                if(d.isLoaded){
                    payloadDocuments.push({
                        title:d.name,
                        key:d.key
                    })
                }else{
                    let s3File = await Storage.put(`document/${this.id}/${d.name}`, d);
                    payloadDocuments.push({
                        title:d.name,
                        key:s3File.key
                    })
                }
            }
            for(let backupDocument of this.backup.documents){
                let documentIndex=this.documents.findIndex(d=>{
                    if (!d.isLoaded){
                        return false
                    }
                    return d.name==backupDocument.title
                })
                if(documentIndex==-1){
			        let res=await Storage.remove(backupDocument.key);
                }
            }
			const input = {
                id:this.id,
				dispatchID: this.dispatchID,
                documents:payloadDocuments,
                //create batches
                batches: this.batches.map(b=>{
                    let newBatch={
                        id:b.id,
                        name:b.name
                    }
                    if(this.fieldKeys(b.id)){
                        newBatch.products=this.fieldKeys(b.id)
                    }
                    return newBatch
                }),
                contract_conditions:[
                    ...(this.smartConditions.map(c=>{
                        let newCondition={
                            ...c
                        }
                        delete newCondition.id
                        return newCondition
                    })),
                    ...(this.businessConditions.map(c=>{
                        let newCondition={
                            ...c
                        }
                        delete newCondition.id
                        return newCondition
                    })),]
			}
			const dispatch = await API.graphql({
				query: updateDispatch,
				variables: {
					input
				}
			}).then(res => res.data.updateDispatch)
            let compareCompanies=this.compareFieldDiff('companies',this.backup.companies.items,"companyID")
            compareCompanies.toDelete=compareCompanies.toDelete.filter(cid=>cid!==this.companyData.id)
            console.log('compareCompanies',compareCompanies);
            let requestsAdd = compareCompanies.toAdd.map((companyID) =>
                API.graphql({
                    query: createCompanyDispatch,
                    variables: {
                        input: {
                            companyID: companyID,
               				dispatchID: this.id
                        },
                    },
                }).then((res) => res.data.createCompanyDispatch)
            );
            let requestsDelete = compareCompanies.toDelete.map((companyID) =>{
                let relationID=this.getDeleteRelaionId(this.backup.companies.items,"companyID",companyID,"dispatchID",this.id)
                return API.graphql({
                    query: deleteCompanyDispatch,
                    variables: {
                        input: {
                            id: relationID,
                        },
                    },
                }).then((res) => res.data.deleteCompanyDispatch)
            });
            await Promise.all(requestsAdd,requestsDelete);
            this.getDispatch()
            this.processing=false
        },
        async createDispatch(){
            this.processing=true
            //documents
			const input = {
				dispatchID: this.dispatchID,
                ownerID: this.backup.owner.company.id,
                status:"Draft",
                documents:documents,
                // batches
                batches: this.batches.map(b=>{
                    let newBatch={
                        id:b.id,
                        name:b.name
                    }
                    if(this.fieldKeys(b.id)){
                        newBatch.products=this.fieldKeys(b.id)
                    }
                    return newBatch
                }),
                //conditions
                contract_conditions:[
                    ...(this.smartConditions.map(c=>{
                        let newCondition={
                            ...c
                        }
                        delete newCondition.id
                        return newCondition
                    })),
                    ...(this.businessConditions.map(c=>{
                        let newCondition={
                            ...c
                        }
                        delete newCondition.id
                        return newCondition
                    })),
                ],
                companiesStatus:[
                    {
                        id: this.backup.owner.company.id,
                        type: 'outcoming',
                        status: 'draft',
                    },
                    ...(this.fieldKeys('companies').map(companyID=>{
                        return {
                            id: companyID,
                            type: 'incoming',
                            status: 'pending',
                        }
                    }))
                ]
            
			}
			const dispatch = await API.graphql({
                query: createDispatch,
				variables: {
                    input
				}
			}).then(res => res.data.createDispatch)
            console.log(input);
            for(let b of input.batches){
                for(let p of b.products){
                    console.log(p);
                    const product = await API.graphql({
                        query: getProduct,
                        variables: {
                            id: p,
                        },
                    }).then((res) => res.data.getProduct);
                    let productDispatches=product.dispatches?product.dispatches:[]
                
                    productDispatches.push(dispatch.id)
                    await API.graphql({
                        query: updateProduct,
                        variables: {
                            input: {
                                id: p,
                                dispatches:productDispatches

                            },
                        },
                    })


                }
            }
            input.batches.forEach(b=>{
                b.pro
            })
            this.fieldKeys("companies").forEach(async companyID=>{
                const companyDispatch = await API.graphql({
                    query: createCompanyDispatch,
                    variables: {
                        input:{
                            dispatchID:dispatch.id,
                            companyID:companyID
                        }
                    }
			    }).then(res => res.data.createCompanyDispatch)
            })
            const companyDispatch = await API.graphql({
                query: createCompanyDispatch,
                variables: {
                    input:{
                        dispatchID:dispatch.id,
                        companyID:this.backup.owner.company.id
                    }
                }
            }).then(res => res.data.createCompanyDispatch)
            //add documents
            let documents=[]
            console.log("start upload doc");
            let requests = this.documents.map(async d=>{
                let s3File = await Storage.put(`document/${dispatch.id}/${d.name}`, d);
                return {title:d.name,key:s3File.key}
            })
			await Promise.all(requests).then(responses => documents = responses);
            const dispatchWithDocuments = await API.graphql({
				query: updateDispatch,
				variables: {
					input:{
                        id:dispatch.id,
                        documents:documents
                    }
				}
			}).then(res => res.data.updateDispatch)
            this.processing=false
            this.refreshData()
            const options={title:'Dispatch Added',size:'sm',okLable:"To Products"} 
            await this.$dialogs.alert("", options).then(res=>{
            })
			this.$router.push('/dispatch');
        },
        parseUploadCSV(csv){
                let rows=csv.split(/\r\n|\n/);
                
                rows.forEach((row,index)=>{
                    if (row.length<1){
                        return
                    }
                    let cols=row.split(parseCVSconfig.colsDevider)
                    let batch={}
                    let batchID=index+""+Date.now()  
                    batch.id=batchID         
                    parseCVSconfig.model.batch.forEach(mb=>{
                        batch[mb.fieldName]=cols[mb.position]
                    })
                    this.addBatchSubmit(batch)
                    let productsColumns=cols.slice(parseCVSconfig.model.products.startPosition)
                    productsColumns.forEach(c=>{
                        c=c.trim()
                        if(c.length<1){
                            return
                        }
                        if(this.products.findIndex(p=>p.id===c)!==-1){
			                this.addFieldItems(batchID,[c])
                        }
                        if(parseCVSconfig.searchByName){
                            let product=this.products.find(p=>{
                                let reg= new RegExp(parseCVSconfig.searchByNameReg.start+c+parseCVSconfig.searchByNameReg.end)
                                return p.title.match(reg)
                            })
                            if(product){
			                    this.addFieldItems(batchID,[product.id])
                            }
                        }
                    })
                })
               
        },

        addBatchOpen(){
            this.addBatchPopupInfo.show=true
        },
        addBatchClose(){
            this.addBatchPopupInfo.show=false
        },
        addBatchSubmit(batch){
            this.batches.push(batch)
            this.setFieldItems(batch.id,[])
            this.addBatchClose()
        },
        deleteBatch(id){
            this.batches.splice(this.batches.findIndex(b=>b.id===id) ,1)
            this.checkedInputs[id]=null
        },
        addProductsOpen(batchID){
			this.addProductsPopupInfo.show=true
			this.addProductsPopupInfo.batchID=batchID
			this.addProductsPopupInfo.products=this.fieldValuesFree(batchID,this.products)
        },
        addProductsClose(){
			this.addProductsPopupInfo.show=false
			this.addProductsPopupInfo.batchID=null
			this.addProductsPopupInfo.products=null
        },
        addProductsSubmit(pruducts){
			this.addFieldItems(this.addProductsPopupInfo.batchID,pruducts)
			this.addProductsClose()
        },


        setCheckedInputs(ev,batchID,id){
            if(!this.checkedInputs[batchID]){
                this.$set(this.checkedInputs,batchID,{})
            }

            let  value=ev.target.checked
            this.$set(this.checkedInputs[batchID],id,value)
            if(!value){
                this.isCheckedAll[batchID]=false
            }else{
                let b=true
                this.fieldValues(batchID,this.products).forEach(p=>{
                    b = b && this.checkedInputs[batchID][p.id] 
                })
                if(b){
                    this.isCheckedAll[batchID]=true
                }
            }
        },
        toggleAllCheckedInputs(batchID){
            if(!this.checkedInputs[batchID]){
                this.$set(this.checkedInputs,batchID,{})
            }
            this.fieldKeys(batchID).forEach(id=>{
                this.$set(this.checkedInputs[batchID],id,!this.isCheckedAll[batchID])
            })
            this.isCheckedAll[batchID]=!this.isCheckedAll[batchID]
        },
        
        openOptions(ev) {            
                if(ev.target.closest(".btn")){
                    return
                }
                let parentElement=ev.target.closest(".spoiler__button")
                parentElement.classList.toggle("active");
                parentElement.nextElementSibling.classList.toggle("active");
        },
        closeQrCodeSettings() {
            this.qrCodeSettings = null;
        },
        closeQrCode() {
            this.qrCode = null;
        },
        closeCsvExample() {
            this.csvExample = false;
        },
        confirmCsvExample() {
            console.log("csv");
        },

        openUploadCsv(){
            this.uploadCsv = true;
        },
        closeUploadCsv() {
            this.uploadCsv = false;
        },


        openAddSmartCondition() {
            this.addSmartCondition.show = true;
            this.addSmartCondition.condition=null
        },
        closeAddSmartCondition() {
            this.addSmartCondition.show = false;
            this.addSmartCondition.condition=null
        },
        submitAddSmartCondition(conditionConfig,isEdit) {
            if(isEdit){
                this.smartConditions[this.smartConditions.findIndex(c=>c.id==conditionConfig.id)]=conditionConfig
            }else{
                this.smartConditions.push(conditionConfig)
            }
            this.closeAddSmartCondition();
        },
        deleteSmartCondition(id){
            this.smartConditions.splice(this.smartConditions.findIndex(c=>c.id==id),1)
        },
        editSmartCondition(id){
            this.addSmartCondition.show=true
            this.addSmartCondition.condition=this.smartConditions.find(c=>c.id==id)
        },

        openAddBusinessCondition() {
            this.addBusinessCondition.show = true;
            this.addBusinessCondition.condition=null
        },
        closeAddBusinessCondition() {
            this.addBusinessCondition.show = false;
            this.addBusinessCondition.condition=null
        },
        submitAddBusinessCondition(conditionConfig,isEdit) {
            if(isEdit){
                this.businessConditions[this.businessConditions.findIndex(c=>c.id==conditionConfig.id)]=conditionConfig
            }else{
                this.businessConditions.push(conditionConfig)
            }
            this.closeAddBusinessCondition();
        },
        deleteBusinessCondition(id){
            this.businessConditions.splice(this.businessConditions.findIndex(c=>c.id==id),1)
        },
        editBusinessCondition(id){
            this.addBusinessCondition.show=true
            this.addBusinessCondition.condition=this.businessConditions.find(c=>c.id==id)
        },
        getDocumentLink(document){
            let url
            console.log("test",document);
            if(document.isLoaded){
                url=document.url
            }else{
                url=URL.createObjectURL(document)

            }
            return url
        }

    },

    


};
</script>
<style scoped>
    .label-btn:hover{
        cursor: pointer;;
    }
</style>